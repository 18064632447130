import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  EmailShareButton,
} from 'react-share';
import { faCopy, faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShareSocial } from 'react-share-social'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Provider, ClapButton } from "@lyket/react";

const Share = ({ socialConfig, tags }) => (
  <div className="post-social">
    <section className="shareSection">
      <div className="clapWrapper">
        <Provider apiKey="pt_fc697d35c9343812e7e2f3d69a483e" disableSessionId="true">
          <ClapButton
            namespace="anmols-blog"
            id={socialConfig.config.lyket_id}
          />
        </Provider>
      </div>
      <div className="shareButtons">
        <CopyToClipboard text={socialConfig.config.url}>
          <span className="customIcon" tooltip="Copy Link">
            <FontAwesomeIcon icon={faLink} className="customStyle" />
          </span>
        </CopyToClipboard>
        <FacebookShareButton url={socialConfig.config.url} className="button is-outlined is-rounded facebook">
          <span className="customIcon" tooltip="Facebook">
            <FontAwesomeIcon icon={faFacebook} />
          </span>
        </FacebookShareButton>
        <LinkedinShareButton url={socialConfig.config.url} className="button is-outlined is-rounded linkedin">
          <span className="customIcon" tooltip="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} />
          </span>
        </LinkedinShareButton>
        <RedditShareButton url={socialConfig.config.url} className="button is-outlined is-rounded reddit">
          <span className="customIcon" tooltip="Reddit">
            <FontAwesomeIcon icon={faReddit} />
          </span>
        </RedditShareButton>
        <EmailShareButton url={socialConfig.config.url} className="button is-outlined is-rounded mail">
          <span className="customIcon" tooltip="Mail">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
        </EmailShareButton>
        {/* <ShareSocial
        url={socialConfig.config.url}
        socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
      /> */}
      </div>
    </section>
  </div >
);

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
  tags: [],
};

export default Share;
